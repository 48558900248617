.test{
    height:85%;
    width:95%;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    margin:70px auto 0px auto;
    /* display:flex;
     */
     scrollbar-color: dark !important;
}

.test::-webkit-scrollbar {
    width:12px;
}

.test::-webkit-scrollbar-track {
    background-color: lightgray;
    border-radius: 45px;
}

.test::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(36, 62, 113, .5);
    border-radius: 45px;
    
}
.test2{
    width:98%;
    margin-bottom: 10px;
}

.test3{
    border: 1px solid rgba(36, 62, 113, 1);
}
.test3:hover{
    border: 2px solid rgba(36, 62, 113, 1);
}

.selected{
    border: 3px solid rgba(36, 62, 113, 1) !important;
}
.spans{
    color: rgba(36, 62, 113, 1);
    font-weight: bolder;
}

.test3 div{
    max-height: 35px;
}

.test3 p{
    min-width: 50%;
}
