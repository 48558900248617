.amountDetailsView {

    padding-top: 0px !important;
    padding-right: 16px !important;
    padding-left: 25px !important;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.RowsPerPageMenu {
    display: block !important;
    padding: 20px !important;
}

.RowsPerPageSelect {
    padding-left: 10px !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-:checked {
    border: 1px #243e71 !important;
}

.DocumentContentDiv {
    /* margin-top: 30px; */
    margin-left: 35px;
    margin-right: 20px;

}

.PathDiv {
    /* padding-bottom: 20px; */
    margin-top: 10px;
    justify-content: flex-start;
    align-content: center;
    display: flex;
}

.PathDiv svg {
    margin-top: auto;
    margin-bottom: auto;
    size: 17px;
    color: #243e71;

}

.FilterDiv {
    display: flex;
    justify-content: center;
    align-content: center;
    /* flex-direction: column; */
    width: 100%;
    /* margin-top:-70px; */
}

.FilterOrganizations {
    width: 90%;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
}

.FilterOrganizations:hover {
    border: 1px solid #243e71 !important;
}


.FilterDateDiv {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-right: auto;
    margin-left: auto;
    /* margin-top: 30px; */
}

.FilterDateDiv input {
    padding: 13px 20px;

}

/* .OrganizationsItems{
    display: block !important;
    padding: 10px !important;

} */

.DocumentControlDiv {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
}

.DocumentControls {
    height: 55px;
    display: flex;
    justify-content: flex-end;
    gap: 25px;
}

.Pagination {
    margin-top: auto;
    margin-bottom: auto;
}

.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
    background-color: #243e71 !important;
}

.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected:hover {
    background-color: #2c5094 !important;
}



.CustomIconButton {
    font-size: 24px !important;
    margin-right: 15px !important;
}

#date-label {
    font-size: 20px !important;
}

.Notification {
    color: #226022 !important;
    background-color: #EDF7ED !important;
}