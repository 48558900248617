.table-container {
    width: 100%;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.table-sticky-first-column {
    position: sticky;
    left: 0;
    background: whitesmoke!important;
}

.table-super-sticky-header {
    position: sticky;
    left: 0;
    background: white;
    z-index: 99 !important;
}

.table-sticky-last-row {
    position: sticky;
    bottom: 0;
    background: white;
}


.table-buttons{
    white-space: "nowrap";
    background-color: #ffffff00 ;
    color:#243e71;
    border:0px;
    padding:10px 16px;
}
  
.table-buttons:hover{
    cursor: pointer;
    color: #2c5094!important;
}
