

.DetailsStatusCircles {
  width: 30px;
  height: 30px;
  border-radius: 60px;
  position: absolute;
  display: inline-block;
  background-color: #243e71;
  /* /* top: -15px; */
  left: -15px; 
  top: 0px;

}

hr {
  width: 100%;
}
.CustomIconButton {
  font-size: 25px !important;
  margin-right: 15px !important;
  color: white !important;
}


.DetailsControlsInnerDiv {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-content: center;
 
}

.DetailsStatusOuterDiv{
  margin-left: 50px;
  min-height: 100px;
  position: relative;
  border-left: 2px  #243e71 solid;
  padding-bottom: 15px;
}

.DetailsStatusTextDiv{
  /* position: absolute; */
  padding-left: 20px;
  padding-top: 7px;
}

.DetailsStatusTextDiv h3{
  margin-top: 0px;
  margin-bottom: 0px;
  color: #243e71;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 1rem;
}


.DetailsStatusTextDiv p{
  margin:0px;
  opacity: 0.7;
}
.DetailsStatusTextDiv p:nth-child(2){
  color:#243e71;
}
.StatusContainer {
  position: relative;
}
.StatusContainer:first-child{
  margin-top:  70px;
}

.StatusContainer:last-child{

  border: 0px solid !important;
}