#toggle {
    width: 0;
    height: 0;
    visibility: hidden;
  }
  
  .toggleLabel {
    display: block;
    width: 60px;
    height:35px;
    background-color: gray;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    transition: 0.5s;
    box-shadow: 0 0 20px #477a8550;
  }

  
  .toggleLabel::after {
    content: "";
    width: 25px;
    height:25px;
    background-color: #e8f5f7;
    position: absolute;
    border-radius: 70px;
    top: 5px;
    left: 4px;
    transition: 0.5s;
  }

  input:checked + .toggleLabel:after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
    }

    
   input:checked + .toggleLabel {
    background-color: #243e71;
    }

    .toggleLabel:active:after {
        width: 50px;
        }

.container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}