.reports-table-container {
    width: 90%;
    margin: 2rem auto;
    
}

.reports-table-sticky-first-column {
    position: sticky;
    left: 0;
    background: whitesmoke!important;
}

.reports-top-row-date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.reports-table-super-sticky-header {
    position: sticky;
    left: 0;
    background: white;
    z-index: 99 !important;
}

.reports-pagination-container {
    display: flex;
    justify-content: flex-end;
}

.reports-top-row-container {
    display: flex;
    flex-direction: row;
    margin-left: 5rem;
}

.reports-top-row-select-container {
    margin: 0 1rem;
}

.reports-date-reset-button-container {
    margin-left: 5rem;
}

.reports-date-csv-download-container {
    margin-left: auto;
}

.reports-breadcrumb-container {
    display: flex;
    flex-direction: row;
    margin-left: 5rem;
}

.reports-table-sticky-last-row {
    position: sticky;
    bottom: 0;
    background: white;
}

.reports-breadcrumb-items {
    display: flex;
    align-items: center;
}
