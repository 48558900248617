*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
#root,
.wrapper {
  height: 100%;
}

body {
  margin: 0;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.BoldFonts {
  font-weight: bolder;
  color: #243e71;
}

.ExpansionPanel {
  padding-top: 5px !important;
  /* background-color: #ebebeb !important; */
  background-color: #f5f5f5 !important;
  color: #243e71 !important;
  border-bottom: 4px #243e71 solid !important;
  /* border-radius: 3px !important; */
}

.ExpansionPanel:hover {
  border-bottom: 4px #243e71 solid !important;
  background-color: #ebebeb !important;
}

.MuiExpansionPanelDetails-root {
  background-color: white !important;
  box-shadow: 0px 1px 0px 0px #2c5094 !important;
  height: auto;
}

.ControlButton {
  width: 300;
  padding: 5px 25px !important;

  height: 50px;
  color: white !important;
  text-decoration: none !important;
  background-color: #243e71;
  /* white-space: nowrap!important; */
}

.ControlButton:disabled {
  background-color: #677288 !important;
  color: rgb(20, 34, 82) !important;
}

.ControlButton:hover {
  background-color: #243e71 !important;
}

.flexCenterCenter {
  display: flex;
  justify-content: center;
  align-content: center;
}

.Typography {
  width: 100%;
  padding-top: 20px;
}

.AppBar {
  background-color: #243e71 !important;
  color: white !important;
}

.SideMenuBox {
  margin-top: 15px;
}

/* .SideMenuSubheaders{
  background-color: #243e71 !important;
  color: white !important;
  display: flex !important;
  gap:15px !important;
  margin:0px !important;
  height:60px !important;
} */

/* .SideMenuSubheaders svg{
  font-size: 40px !important;
  margin-top: 20px !important;
} */

/* .SideMenuLinks{
  text-decoration: none !important;
  color: white !important;
  font-size: 13px;
  padding-left: 15px;
} */

.menuItemLogout:hover {
  color: white;
  background-color: #243e71 !important;
}