.details-top-row-date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.details-pagination-container {
    display: flex;
    justify-content: flex-end;
}

.details-top-row-container {
    display: flex;
    flex-direction: row;
    margin-left: 5rem;
}

.details-top-row-select-container {
    margin: 0 1rem;
}

.details-date-reset-button-container {
    margin-left: 5rem;
}

.details-date-csv-download-container {
    margin-left: auto;
}

.details-breadcrumb-container {
    display: flex;
    flex-direction: row;
    margin-left: 5rem;
}

.details-breadcrumb-items {
    display: flex;
    align-items: center;
}
