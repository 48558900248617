#loginContainer {
  width: 100%;
  height: 100vh;
  background: #243e71;
}

.loginTitle {
  text-align: center;
  color: #fff;
  font-size: 3.5rem;
}

.loginCard {
  width: 600px;
  background: #e4edf6;
  padding: 30px 40px 40px 40px;
}

.formGroup {
  width: 100%;
  margin-bottom: 40px;
}

.actionRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.submitBtn.submitBtn {
  background-color: #35599e;
  color: #fff;
  top: 10px;
  width: 250px;
  padding: 10px;
  height: 60px;
}

.submitBtn.submitBtn:hover {
  background-color: #1976d2;
}

.forgotPassword {
  margin-top: 8px;
  font-size: 0.9rem;
  color: #a6aeb7;
}

@media screen and (max-width: 650px) {
  .loginCard {
    width: 450px;
  }
}