.test{
    height:85%;
    width:95%;
    overflow-y: auto;

    margin:1px auto 0px auto;
    /* display:flex;
     */
     scrollbar-color: dark !important;
}

.test::-webkit-scrollbar {
    width:12px;
}

.test::-webkit-scrollbar-track {
    background-color: lightgray;
    border-radius: 45px;
}

.test::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(36, 62, 113, .5);
    border-radius: 45px;
    
}
.test2{
    width:98%;
    margin-bottom: 10px;
}

.test3{
    border: 1px solid rgba(36, 62, 113, 1);
    width: 100%;
    height: 130px;
    border-radius: 15px 15px 15px 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.test3:hover{
    border: 2px solid rgba(36, 62, 113, 1);
}

.mpampis{
    border: 3px solid rgba(36, 62, 113, 1) !important;
}
.spans{
    color: rgba(36, 62, 113, 1);
    font-weight: bolder;
}

.test3 div{
    max-height: 35px;
}

.test3 p{
    min-width: 50%;
}



.round {
    position: relative;
  }
  
  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }
  
  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }
  
  .round input[type="checkbox"] {
    visibility: hidden;
  }
  
  .round input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }
  
  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
  
  .container {
    margin: 0 auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f1f2f3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
  }